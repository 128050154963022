/**
 * Takes entry data and creates `range` which is either the 'part' (season) or "start-end" from
 * episode range based on what exists, otherwise empty string.
 */
export function createEntryEpisodeRangeKey(data) {
    data.forEach((x, xi) => {
        if (x.part != null) {
            x.range = `${x.part}`;
        }
        else if ((x.ep_start != null) && (x.ep_end != null)) {
            x.range = `${x.ep_start}-${x.ep_end}`;
        }
        else {
            x.range = '';
        }
    })
    return data;
}
