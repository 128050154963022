const {
    REACT_APP_API_BASE = '',
} = process.env;


export async function request(route, { method='get', query={} } = {}) {
    const headers = {};

    let url = REACT_APP_API_BASE.replace(/\/$/, '');
    url = `${url}${(route[0] === '/') ? '' : '/'}${route}`;
    if (Object.entries(query).length != 0) {
        const querystr = new URLSearchParams(query).toString();
        url = `${url}?${querystr}`
    }

    headers['Accept'] = 'application/json';

    const response = await fetch(url, {
        method: method.toUpperCase(),
        mode: "cors",
        headers,
        redirect: 'follow',
    });
    return response.json();
}
