/**
 * Special Props
 *
 * - headers: The headers accept a list of object.
 *     - key: The key which the data is pulled from.
 *     - text: The text for the header column.
 *     - sortKey: If sortable, which key is returned when sort is initiated.
 *     - onClick: If clickable, the callback that is called.
 *     - onClickKey: If set, the key to return when clicked; otherwise key is used.
 * - data: The data as a series of objects, the data values are pulled using `key` from headers.
 */
function Tbl(props = {}) {
    const {
        headers = [],
        data = [],
        onClickKey = 'id',
        onRowClick = null,
        idKey = 'id',
        sortKey = '',
        sortDir = '',
        onSortChange = (_key, _dir) => {},
    } = props;

    const handleRowClick = (e, keyValue) => {
        e.preventDefault();

        if (onRowClick != null) {
            onRowClick(keyValue);
        }
    };

    const handleSortChange = (e, clickedSortKey) => {
        e.preventDefault();

        if ((clickedSortKey != null) && (clickedSortKey ===  sortKey)) {
            let newSortKey = clickedSortKey;
            let newSortDir = sortDir;
            if (sortDir === 'up') {
                newSortDir = 'down';
            }
            else if (sortDir === 'down') {
                newSortKey = '';
                newSortDir = '';
            }
            else if (sortDir === '') {
                newSortDir = 'up';
            }
            onSortChange(newSortKey, newSortDir);
        }
        else if (clickedSortKey != null) {
            onSortChange(clickedSortKey, 'up');
        }
    };

    const handleCellClick = (e, headIdx, obj) => {
        e.preventDefault();
        const key = headers[headIdx].onClickKey ?? headers[headIdx].key;
        const cb = headers[headIdx].onClick;
        if (cb != null) {
            cb(obj[key]);
        }
    };

    const sortChar = (sortDir === 'up') ? '^' : (sortDir === 'down') ? 'v' : '';

    const hdrrow = (
        <tr>
            {
                headers.map((h, hi) => (
                    <th key={`hdr-${hi}-${Date.now()}`} onClick={(e) => handleSortChange(e, h.sortKey)}>
                        {(sortKey === h.key) ? sortChar : ''}{h.text}
                    </th>
                ))
            }
        </tr>
    );
    console.log('Header Row', hdrrow);

    const rows = data.map((x, xi) => (
        <tr key={`row-${xi}-${Date.now()}`} onClick={(e) => handleRowClick(e, x[onClickKey])}>
            {
                headers.map((h, hi) => (
                    <td
                        key={`col-${xi}-${hi}-${Date.now()}`}
                        onClick={(e) => handleCellClick(e, hi, x)}
                    >
                        {x[h.key] ?? ""}
                    </td>
                ))
            }
        </tr>
    ));
    console.log('Data Rows', rows);

    return (
        <div>
            <table className="table is-fullwidth">
                {hdrrow}
                {rows}
            </table>
        </div>
    )
}


export default Tbl;
