import {useEffect, useState} from 'react';

import { request } from '../utils/request';
import { createEntryEpisodeRangeKey } from "../utils/data";

import Tbl from './Table';
import Pager from './Pager';

function EntrySearch(props = {}) {
    const {
        onGotoCollection = (_id) => {},
        onGotoSeries = (_id) => {},
    } = props;

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(100);
    const [total, setTotal] = useState(0);
    const [query, setQuery] = useState('');
    const [regexp, setRegexp] = useState(false);
    const [sortKey, setSortKey] = useState('');
    const [sortDir, setSortDir] = useState('');

    useEffect(() => {
        const queryopts = Object.fromEntries(
            Object.entries({
                page,
                query: (query === '') ? null : query,
                regexp,
                sort_key: sortKey,
                sort_dir: sortDir,
            }).filter(([_, v]) => v != null)
        );
        request('/api/movies', { query: queryopts }).then((resp) => {
            console.debug('Got Data:', resp);
            resp.data = createEntryEpisodeRangeKey(resp.data);
            setTotal(resp.metadata.total);
            setData(resp.data);
        }).catch((err) => {
            console.error('Failed to fetch data:', err);
        })
    }, [query, page, sortKey, sortDir]);

    return (
        <div className="content">
            <div className="level">
                <div className="level-left">
                    <div className="title level-item">Entries</div>
                </div>
            </div>

            <div className="block"></div>

            <div className="field">
                <div class="control">
                    Search:
                    <label class="radio">
                        <input type="radio" name="answer" checked={!regexp} onClick={() => setRegexp(false)} />
                        Natural
                    </label>
                    <label class="radio">
                        <input type="radio" name="answer" checked={regexp} onClick={() => setRegexp(true)} />
                        Regexp
                    </label>
                </div>
            </div>

            <div className="field">
                <div class="control">
                    <input
                        className='input'
                        type='text'
                        placeholder='Search'
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>
            </div>

            <div className='level'>
                <div className='level-item'>
                    <Pager limit={limit} page={page} total={total} onPageChange={(p) => setPage(p)} />
                </div>
            </div>

            <Tbl
                headers={[
                    {key: 'year', sortKey: 'year', text: 'Year'},
                    {key: 'title', sortKey: 'title', text: 'Title'},
                    {key: 'range', text: 'Part'},
                    {key: 'total_part', text: 'Total'},
                    {key: 'series', text: 'Series', onClick: onGotoSeries, onClickKey: 'series_id'},
                    {key: 'collection', text: 'Collection', onClick: onGotoCollection, onClickKey: 'collection_id'},
                ]}
                data={data}
                sortKey={sortKey}
                sortDir={sortDir}
                onSortChange={(key, dir) => {setSortKey(key); setSortDir(dir);}}
            />

            <div className='level'>
                <div className='level-item'>
                    <Pager limit={limit} page={page} total={total} onPageChange={(p) => setPage(p)} />
                </div>
            </div>
        </div>
    );
}

export default EntrySearch;
