import { useState, useEffect } from 'react';

import EntrySearch from './components/EntrySearch';
import Collections from './components/Collections';
import CollectionView from './components/CollectionView';
import Series from './components/Series';
import SeriesItem from './components/SeriesItem';

function NavBar(props = {}) {
    const {
        onSelect = (_selection) => {},
    } = props;

    const [burgerActive, setBurgerActive] = useState(false);

    const _handleBurger = (e) => {
        setBurgerActive(!burgerActive);
    };

    const _handleItemClick = (e) => {
        e.preventDefault();
        onSelect(e.target.id);
        setBurgerActive(false);
    };

    return (
        <nav className='navbar'>
            <div className="navbar-brand">
                <div className='title is-5'>Cora's Movie DB</div>
            </div>

            <div  className={`navbar-burger ${burgerActive ? 'is-active' : ''}`} onClick={_handleBurger}>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </div>

            <div id="navbarBasicExample" className={`navbar-menu ${burgerActive ? 'is-active' : ''}`}>
                <div className="navbar-start">
                    <a className="navbar-item" id="home" onClick={_handleItemClick}>Entries</a>
                    <a className="navbar-item" id="collections" onClick={_handleItemClick}>Collections</a>
                    <a className="navbar-item" id="series" onClick={_handleItemClick}>Series</a>
                </div>

                <div className='navbar-end'>
                    <a className="navbar-item" id="login" onClick={_handleItemClick}>Login</a>
                </div>
            </div>
        </nav>
    );
}

function App() {
    const [view, setView] = useState(null);

    const gotoCollection = (viewname) => {
        return (id) => {
            let retfn;
            console.log('Setting the return function to', viewname);
            if (viewname === 'Collections') {
                retfn = gotoCollectionList;
            }
            else if (viewname === 'EntrySearch') {
                retfn = gotoEntries;
            }
            else {
                console.warn('Unknown view type', viewname);
                retfn = gotoEntries;
            }
            setView(<CollectionView id={id} onReturn={retfn} />);
        };
    };

    const gotoSeries = (viewname) => {
        return (id) => {
            let retfn;
            console.log('Setting the return function to', viewname);
            if (viewname === 'Collections') {
                retfn = gotoCollectionList;
            }
            else if (viewname === 'Series') {
                retfn = gotoSeriesList;
            }
            else if (viewname === 'EntrySearch') {
                retfn = gotoEntries;
            }
            else {
                console.warn('Unknown view type', viewname);
                retfn = gotoEntries;
            }
            setView(<SeriesItem id={id} onReturn={retfn} />);
        };
    };

    const gotoCollectionList = () => {
        setView(<Collections
            onCollectionSelect={gotoCollection('Collections')}
        />);
    };

    const gotoSeriesList = () => {
        setView(<Series
            onSeriesSelect={gotoSeries('Series')}
        />);
    };

    const gotoEntries = () => {
        setView(<EntrySearch
            onGotoSeries={gotoSeries('EntrySearch')}
            onGotoCollection={gotoCollection('EntrySearch')}
        />);
    };

    useEffect(() => {
        gotoEntries();
    }, []);

    const onViewSelect = (selection) => {
        if (selection === 'home') {
            gotoEntries();
        }
        else if (selection === 'collections') {
            gotoCollectionList();
        }
        else if (selection === 'series') {
            gotoSeriesList();
        }
    };

    return (
        <div className='content'>
            <NavBar onSelect={onViewSelect} />

            <div className='block'></div>

            {view}
        </div>
    );
}

export default App;
