import {useEffect, useState} from "react";

import { request } from '../utils/request';

import Tbl from './Table';
import Pager from './Pager';
import SearchForm from './SearchForm';

function Collections(props = {}) {
    const {
        onCollectionSelect = (_id) => {},
    } = props;

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(100);
    const [sortKey, setSortKey] = useState('');
    const [sortDir, setSortDir] = useState('');
    const [query, setQuery] = useState('');
    const [regexp, setRegexp] = useState(false);

    const handleRowClick = (id) => {
        // TODO Handle switching to new view
        onCollectionSelect(id);
    }

    const values = { regexp, query };
    const setters = { regexp: setRegexp, query: setQuery };

    useEffect(() => {
        const queryopts = {
            page,
            limit,
            sort_key: sortKey,
            sort_dir: sortDir,
            query,
            regexp,
        };
        request('/api/collections', { query: queryopts }).then((resp) => {
            console.debug('Got Data:', resp);
            setTotal(resp.metadata.total);
            setData(resp.data);
        }).catch((err) => {
            console.error('Failed to fetch data:', err);
        })
    }, [page, limit, sortKey, sortDir, regexp, query]);


    return (
        <div className="content">
            <div className="level">
                <div className="level-left">
                    <div className="title level-item">Collections</div>
                </div>
            </div>

            <div className="block"></div>

            <SearchForm values={values} setters={setters} />

            <div className='level'>
                <div className='level-item'>
                    <Pager limit={limit} page={page} total={total} onPageChange={(p) => setPage(p)} />
                </div>
            </div>

            <Tbl
                headers={[
                    {key: 'text', sortKey: 'text', text: 'Collection'},
                ]}
                data={data}
                onClickKey='id'
                onRowClick={handleRowClick}
                sortKey={sortKey}
                sortDir={sortDir}
                onSortChange={(key, dir) => {setSortKey(key); setSortDir(dir);}}
            />

            <div className='level'>
                <div className='level-item'>
                    <Pager limit={limit} page={page} total={total} onPageChange={(p) => setPage(p)} />
                </div>
            </div>
        </div>
    );
}

export default Collections;
