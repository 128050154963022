function SearchForm(props = {}) {
    const {
        values = {},
        setters = {},
    } = props;

    const regexp = values.regexp ?? false;

    const handleChange = (e, key) => {
        e.preventDefault();
        const setr = setters[key];
        if (setr != null) {
            setr(e.target.value);
        }
    };

    const handleBool = (e, key, state) => {
        e.preventDefault();
        const setr = setters[key];
        if (setr != null) {
            setr(state);
        }
    };

    return (
        <div>
            <div className="field">
                <div class="control">
                    Search:
                    <label class="radio">
                        <input type="radio" name="answer" checked={!regexp} onClick={(e) => handleBool(e, 'regexp', false)} />
                        Natural
                    </label>
                    <label class="radio">
                        <input type="radio" name="answer" checked={regexp} onClick={(e) => handleBool(e, 'regexp', true)} />
                        Regexp
                    </label>
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <input className="input" type="text" placeholder="Search" value={values.query ?? ''} onChange={(e) => handleChange(e, 'query')}/>
                </div>
            </div>
        </div>
    );
}

export default SearchForm;
