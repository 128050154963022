/**
 * Provides a pagination widget for integrating querying by page.
 */
function Pager(props = {}) {
    const {
        limit = 100,
        total = 0,
        page = 0,
        onPageChange = (_newpage) => {},
    } = props;

    const maxPage = Math.ceil(total / limit);

    const handleClick = (e, newpage) => {
        e.preventDefault();
        onPageChange(newpage);
    };

    const first = (page <= 1) ? [] : [
        <li><a href="#" class="pagination-link" onClick={(e) => handleClick(e, 0)}>1</a></li>,
        <li><span class="pagination-ellipsis">&hellip;</span></li>,
    ];
    const before = (page < 1) ? [] : [
        <li><a href="#" class="pagination-link" onClick={(e) => handleClick(e, page - 1)}>{page}</a></li>,
    ];
    const after = (page >= (maxPage - 1)) ? [] : [
        <li><a href="#" class="pagination-link" onClick={(e) => handleClick(e, page + 1)}>{page + 2}</a></li>,
    ];
    const last = (page >= (maxPage - 2)) ? [] : [
        <li><span class="pagination-ellipsis">&hellip;</span></li>,
        <li><a href="#" class="pagination-link" onClick={(e) => handleClick(e, maxPage - 1)}>{maxPage}</a></li>,
    ];

    const pages = [
        ...first,
        ...before,
        <li><a class="pagination-link is-current">{page + 1}</a></li>,
        ...after,
        ...last,
    ];

    return (
        <nav className="pagination">
            <ul class="pagination-list">
                {pages}
            </ul>
        </nav>
    );
}

export default Pager;
