import {useEffect, useState} from "react";

import { request } from "../utils/request";
import { createEntryEpisodeRangeKey } from "../utils/data";

import Tbl from "./Table";
import Pager from './Pager';
import SearchForm from './SearchForm';

function CollectionView(props = {}) {
    const {
        id = null,
        onReturn = () => {},
        onGotoCollection = (_id) => {},
        onGotoSeries = (_id) => {},
    } = props;

    const [title, setTitle] = useState('Collection');
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(100);
    const [sortKey, setSortKey] = useState('');
    const [sortDir, setSortDir] = useState('');
    const [query, setQuery] = useState('');
    const [regexp, setRegexp] = useState(false);

    const values = { regexp, query };
    const setters = { regexp: setRegexp, query: setQuery };

    useEffect(() => {
        const queryopts = {
            page,
            limit,
            sort_key: sortKey,
            sort_dir: sortDir,
            query,
            regexp,
        };
        request(`/api/collections/${id}/entries`, { query: queryopts }).then((resp) => {
            console.debug('Got Data:', resp);
            resp.data = createEntryEpisodeRangeKey(resp.data);
            setTotal(resp.metadata.total);
            setData(resp.data);
        }).catch((err) => {
            console.error('Failed to fetch data:', err);
        })

        request(`/api/collections/${id}`, { query: queryopts }).then((resp) => {
            setTitle(resp.text);
        }).catch((err) => {
            console.error('Failed to fetch data:', err);
        })
    }, [page, limit, sortKey, sortDir, regexp, query]);

    return (
        <div>
            <div className="level">
                <div className="level-left">
                    <div className="delete level-item is-medium" onClick={() => onReturn()}></div>
                    <div className="title level-item">"{title}"</div>
                </div><div className="level-right">
                </div>
            </div>

            <div className="block"></div>

            <SearchForm values={values} setters={setters} />

            <div className='level'>
                <div className='level-item'>
                    <Pager limit={limit} page={page} total={total} onPageChange={(p) => setPage(p)} />
                </div>
            </div>

            <Tbl
                headers={[
                    {key: 'year', sortKey: 'year', text: 'Year'},
                    {key: 'title', sortKey: 'title', text: 'Title'},
                    {key: 'range', text: 'Part'},
                    {key: 'total_part', text: 'Total'},
                    {key: 'series', text: 'Series', onClick: onGotoSeries, onClickKey: 'series_id'},
                    {key: 'collection', text: 'Collection', onClick: onGotoCollection, onClickKey: 'collection_id'},
                ]}
                data={data}
                sortKey={sortKey}
                sortDir={sortDir}
                onSortChange={(key, dir) => {setSortKey(key); setSortDir(dir);}}
            />

            <div className='level'>
                <div className='level-item'>
                    <Pager limit={limit} page={page} total={total} onPageChange={(p) => setPage(p)} />
                </div>
            </div>
        </div>
    );
}

export default CollectionView;
